@font-face {
  font-family: Montserrat;
  src: url('./assets/font/Montserrat-VariableFont_wght.ttf');
}
@font-face {
  font-family: Karla;
  src: url('./assets/font/Karla-VariableFont_wght.ttf');
}


.App {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-weight: normal;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #E7EBE5FF !important;
  font-family: 'Karla', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: Montserrat, sans-serif !important;
  font-weight: bold;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 2rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: all 0.32s ease-in-out;
}
nav.navbar h1{
  font-family: Montserrat, sans-serif;
  font-weight: bolder;
  font-size: clamp(.5rem, 8vw, 2rem);
  letter-spacing: .2rem;
  justify-self: left;
  color: rgba(231, 235, 229, 0);
  margin: 0;
  padding: 0 0 0 clamp(0em, 2vw, .5em);

  transition: all 0.3s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0;
  min-height: 4.5em;
  background: #000000;
}

nav.navbar.scrolled h1{
  color: rgb(231, 235, 229);
}

nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.45);
  font-size: 1.2rem;
  opacity: 0.75;
  text-align: center;
  padding: .1rem;
  margin: 0 0 0 1rem;
  overflow: hidden;
  background-position: left;
  border-radius: 3rem;
  border: .1rem solid transparent;

  transition: all .3s ease-in-out;
}


nav.navbar .navbar-nav a.nav-link.navbar-link div{
  border: .1rem solid rgba(255, 255, 255, 0.45);

  transition: all .3s ease-in-out;

 }

nav.navbar .navbar-scrolled .navbar-nav .nav-link.navbar-link {
  margin: 1rem 0 1rem 1rem;
  overflow: hidden;

  transition: all .3s ease-in-out;
}




nav.navbar .navbar-nav a.nav-link.navbar-link:hover{
  overflow: hidden;
  background: url("./assets/img/hBG.png");
  --bg-size: 300%;
  animation: funny-effect linear 15s infinite;
  background-size: 2.5rem;
  pointer-events: auto;

  transition: all .3s ease-in-out;
}
nav.navbar .navbar-scrolled .navbar-nav a.nav-link.navbar-link:hover{
  opacity: 1;
  /*border: .1rem solid white;*/
  background-color: rgba(102, 102, 102, 0);
  overflow: hidden;
  background-position: left;
  animation: funny-effect linear 50s infinite;

  transition: all .3s ease-in-out;
}
nav.navbar .navbar-nav a.nav-link.navbar-link.active{
  background: #E7EBE5FF left;
  pointer-events: auto;
  --bg-size: 400%;
  opacity: 1;
  border: .1rem solid transparent;

  transition: all .3s ease-in-out;

}

nav.navbar .navbar-nav  div{
  position: relative;
  border-radius: 2rem;
  padding: .5rem 4rem .5rem 4rem;
  align-content: center;
}
nav.navbar .navbar-nav a.nav-link.navbar-link.active div{
  border: .1rem solid black;
  color: black;
}

nav.navbar .navbar-nav a.nav-link.navbar-link.active:hover div{
  border: .1rem solid black;
  color: black;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover div{
  border: .1rem solid #E7EBE5FF;

  color: #E7EBE5FF;
}

nav.navbar .navbar-scrolled .navbar-nav a.nav-link.navbar-link.active{

   opacity: 1;
   border: .1rem solid transparent;
   background-color: #E7EBE5FF;
   overflow: hidden;
   background-position: center;


   transition: all .3s ease-in-out;
 }
span.navbar-text {
  display: flex;
  align-items: center;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #E7EBE5FF;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #E7EBE5FF;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #E7EBE5FF;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #E7EBE5FF;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #E7EBE5FF;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin: 0;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
    align-content: center;
  place-content: center;
}

@media (min-height: 40em) {
  .banner {
    height: min(100vh, 1080px);
    width: auto;
  }
}

.banner .info-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  margin: 0 clamp(4em, 5vh, 6em) 0 clamp(1em, 5vw, 6em);
  max-width: 1500px;

}

@media (max-height: 40em) {
  .banner .info-container{
    margin: 8em clamp(1em, 5vw, 6em);
  }
}

.banner h1 {
  font-size: clamp(2.5rem, 15vw, 4.5rem);
  font-weight: bold;
  letter-spacing: 0.08rem;
  line-height: 1;
}

.banner h2 {
  font-size: clamp(1.5rem, 8vw, 2.5rem);
}
.banner p {
  color: #B8B8B8;
  font-size: clamp(1rem, 6vw, 1.8rem);
  letter-spacing: 0.08rem;
}

.banner .resume, .contact form .submit-button {
  color: #E7EBE5FF;
  text-decoration: none;
  font-weight: bold;
  font-size: clamp(.5rem, 6vw, 2rem);
  letter-spacing: 0.08rem;
  display: inline-flex;
  border: .1rem solid transparent;
  padding: .2rem;
  margin-top: 3rem;
  --bg-size: 300%;

  background: rgba(0, 0, 0, 0) url("./assets/img/hBG.png") left;
  animation: funny-effect linear 45s infinite;
  background-size: 2.5rem;
  pointer-events: auto;
  transition: background 0.3s ease-in-out;
}
.contact form .submit-button{

  --bg-size: 200vh;
  animation: funny-effect linear 45s infinite;
}

.banner .resume span, .contact form .submit-button span{
  border: .1rem solid #E7EBE5FF;
  padding: .8em;
  pointer-events: none;
  transition: color, border .3s ease-in-out;
}

.banner .resume:hover span, .contact form .submit-button:hover span{
  border-color: black;
}

.banner .resume:hover, .contact form .submit-button:hover {
  color: black;
  background: #E7EBE5FF;;
  background-size: 2.5rem;
  pointer-events: auto;

}


@media (min-height: 40em) {
  .moreButton {
    position: absolute;
    padding: 0;
    align-self: center;
    color: #E7EBE5FF;
    font-weight: bold;
    font-size: clamp(1rem, 8vw, 2.5rem);
    animation: updown 4s ease-out infinite;
    top: 90dvh;
    left: 50%;
    transition: top 0.3s ease-in-out;
  }
}

.moreButton.scrolled {
  color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out;
  pointer-events: none;
}

.moreButton.scrolled:hover {
  cursor: auto;
}

@keyframes updown {
  0% {
    transform: translateY(-.3rem) translateX(-50%) scaleX(4) scaleY(3);
  }
  8% {
    transform: translateY(.3rem)translateX(-50%) scaleX(4) scaleY(3);
  }
  16% {
    transform: translateY(-.3rem)translateX(-50%) scaleX(4) scaleY(3);
  }
  24% {
    transform: translateY(.3rem)translateX(-50%) scaleX(4) scaleY(3);
  }
  32% {
    transform: translateY(-.3rem)translateX(-50%) scaleX(4) scaleY(3);
  }
  100% {
    transform: translateY(-.3rem)translateX(-50%) scaleX(4) scaleY(3);
  }
}

.banner .video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
 overflow: hidden;
}

.banner .video-container video {
  width: 100vw;
  height: auto;
  top: -7%;
  left: 0;
  transition: top 0.5s;

  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
@media only screen and (max-aspect-ratio: 1/1) {
  .banner .video-container video{
    width: auto;
    height: 100vh;
    top: 0;
    left: auto;
    right: 0;

  }
}


/************ Projects Css ************/
:root {
  --pj1-color1: rgb(189, 36, 95);
  --pj1-color2: rgb(255, 93, 152);
  --pj2-color1: rgb(246, 40, 40);
  --pj2-color2: rgb(255, 186, 36);
  --pj3-color1: rgb(163, 40, 246);
  --pj3-color2: rgb(77, 85, 255);
  --pj4-color1: rgb(0, 123, 255);
  --pj4-color2: rgb(102, 178, 255);
  --pj5-color1: rgb(52, 168, 83);
  --pj5-color2: rgb(102, 255, 153);

  --pj1-color1a: rgba(189, 36, 95, 0.55);
  --pj1-color2a: rgba(255, 93, 152, 0.55);
  --pj2-color1a: rgba(246, 40, 40, 0.55);
  --pj2-color2a: rgba(255, 186, 36, 0.55);
  --pj3-color1a: rgba(163, 40, 246, 0.55);
  --pj3-color2a: rgba(77, 85, 255, 0.55);
  --pj4-color1a: rgba(0, 123, 255, 0.55);
  --pj4-color2a: rgba(102, 178, 255, 0.55);
  --pj5-color1a: rgba(52, 168, 83, 0.55);
  --pj5-color2a: rgba(102, 255, 153, 0.55);
}


.project .funny-title a{
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: Bold;
  text-align: center;
  color: #E7EBE5FF;
  transition: all 0.3s ease-in-out;
  --bg-size: 400%;
}

.project:nth-child(1) .funny-title a{
  background: linear-gradient(
          90deg,
          var(--pj1-color1),
          var(--pj1-color2),
          var(--pj1-color1)
  ) 0 0 / var(--bg-size) 50%;
  -webkit-background-clip: text;
  background-clip: text;
  pointer-events: auto;
}

.project:nth-child(2) .funny-title a{
  background: linear-gradient(
          90deg,
          var(--pj2-color1),
          var(--pj2-color2),
          var(--pj2-color1)
  ) 0 0 / var(--bg-size) 50%;
  -webkit-background-clip: text;
  background-clip: text;
  pointer-events: auto;
}

.project:nth-child(3) .funny-title a{
  background: linear-gradient(
          90deg,
          var(--pj3-color1),
          var(--pj3-color2),
          var(--pj3-color1)
  ) 0 0 / var(--bg-size) 50%;
  -webkit-background-clip: text;
  background-clip: text;
  pointer-events: auto;
}

.project:nth-child(4) .funny-title a{
  background: linear-gradient(
          90deg,
          var(--pj4-color1),
          var(--pj4-color2),
          var(--pj4-color1)
  ) 0 0 / var(--bg-size) 50%;
  -webkit-background-clip: text;
  background-clip: text;
  pointer-events: auto;
}

/* Fifth Project Color Scheme */
.project:nth-child(5) .funny-title a {
  background: linear-gradient(
          90deg,
          var(--pj5-color1),
          var(--pj5-color2),
          var(--pj5-color1)
  ) 0 0 / var(--bg-size) 50%;
  -webkit-background-clip: text;
  background-clip: text;
  pointer-events: auto;
}

.project:nth-child(5)::after {
  background: linear-gradient(90deg, var(--pj5-color1), var(--pj5-color2));
}

.project:nth-child(5) .my-tag {
  background: linear-gradient(90.21deg, var(--pj5-color1a), var(--pj5-color2a));
}


.project .funny-title{
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: Bold;
  text-align: center;
  color: #E7EBE5FF;
  transition: all 0.5s ease-in-out;
  pointer-events: none;

  margin: 0 0 1rem 0;
  display: flex;
justify-content: center;
  align-items: center;

}

.project .funny-title:hover{
  transform: scale(1.1);
}

.project .funny-title a:hover{
  color: transparent;
  animation: funny-effect linear 8s infinite;
}

@keyframes funny-effect {
  to {
    background-position: var(--bg-size) 0;
  }
}

/* projects */
.project {
  background-color: black;
  display: flex;
  flex-direction: row;

  height: auto;
  width: 100%;
  margin-bottom: 3rem;
  border-radius: 2.5rem;
  transition: all ease-in-out 0.8s;
  overflow: hidden;
}
.project:nth-child(odd) {
  flex-direction: row-reverse; /* Reverse row direction for odd projects */
}

/* For even projects, media on the left */
.project:nth-child(even) {
  flex-direction: row; /* Normal row direction for even projects */
}

.project::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: .5rem;
}

.project:nth-child(1)::after{
  background: linear-gradient(90deg, var(--pj1-color1), var(--pj1-color2));
}

.project:nth-child(2)::after{
  background: linear-gradient(90deg, var(--pj2-color1), var(--pj2-color2));
}

.project:nth-child(3)::after{
  background: linear-gradient(90deg, var(--pj3-color1), var(--pj3-color2));
}

.project:nth-child(4)::after{
  background: linear-gradient(90deg, var(--pj4-color1), var(--pj4-color2));
}

.project .demo-link{
  width: auto;
  height: auto;
  display: flex;
  top: 0;
}

.project .demo-link .demo{
  object-fit: cover;
  width: auto;
  height: auto;
  aspect-ratio: 3/2;
  max-width: 40rem;
  margin: 0;
  padding: 0;
}

.project .project-details {
  width: auto;
  /*margin: 2rem 1rem 1rem 1rem;*/
  /*padding: 0 1rem 0 1rem;*/

  margin: 1rem 3rem;
  padding: 3rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

}

.project-details p {
  width: 100%;
  text-align: left;
  font-family: Karla, sans-serif;
  font-weight: normal;
  font-size: 1.3rem;
  margin: 0 0 2rem 0;

  color: #B8B8B8;

}


.project-details .project-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;

  margin: 0 0 1rem 0;

  align-items: center;
}

.project-details .my-tag {
  font-size: .7rem;
  margin: .3rem .6rem .3rem 0;
  padding: .3rem .6rem .3rem .6rem;
  border: 0.1rem solid rgba(255, 255, 255, 0.18);
  display: inline-block;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
  color: rgba(255, 255, 255, 0.71);

  max-height: 2rem;
}

.project:nth-child(1) .my-tag{
  background: linear-gradient(90.21deg, var(--pj1-color1a), var(--pj1-color2a));
}

.project:nth-child(2) .my-tag{
  background: linear-gradient(90.21deg, var(--pj2-color1a), var(--pj2-color2a));
}

.project:nth-child(3) .my-tag{
  background: linear-gradient(90.21deg, var(--pj3-color1a), var(--pj3-color2a));
}

.project:nth-child(4) .my-tag{
  background: linear-gradient(90.21deg, var(--pj4-color1a), var(--pj4-color2a));
}

.project-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
;
  align-items: center;
}
.project-links a {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0 .5em;
}
.project-links a img {
  object-fit: contain;
  transition: all 0.3s ease-in-out;
  width: auto;
  max-height: 3.5rem;
  margin: .5rem 0 .5rem 0;
}
.project-links a:hover img {
  filter: drop-shadow(0em 0 0.3em rgba(231, 235, 229, 0.9));
  transform: scale(1.1);
}
.project-links .googleImg {
    width: 11rem;
  max-height: 4rem;
    height: auto;
    object-fit: contain;
}

.projects, .contact{
  background-color: rgb(18, 18, 18);
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
}



.header-bg {
  width: 100%;
  height: auto;
  margin: 4.5rem 0 2rem 0;
  padding: 2rem 0;
  text-align: center;
  background: url("./assets/img/hBG.png");
  background-size: 2.5rem;
 }

.header-bg  h1 {
  all:initial;
  color: #E7EBE5FF;
  font-weight: bold;
  padding: 2rem;
  font-size: clamp(1rem, 8vw, 3rem);
  letter-spacing: .2rem;
}

@media only screen and (max-width: 1200px) {
  .projects {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .projects .container {
    max-width: 700px;
  }

  .project:nth-child(odd) {
    flex-direction: column;  /* Reverse row direction for odd projects */
  }

  /* For even projects, media on the left */
  .project:nth-child(even) {
    flex-direction: column;  /* Normal row direction for even projects */
  }

  .project {
    width: 100%;
    max-width: 700px;
    height: auto;
    display: flex;
    flex-direction: column-reverse; /* Change to column-reverse to have media at the bottom */
    margin: 0 0 2rem 0;
  }

  .project .project-details {
    width: 100%;
    float: none;
    height: auto;
    object-fit: contain;
    margin: 0;
    padding: 2rem 1rem 1rem 1rem;
  }

  .project .project-details p {
    width: 100%;
    padding: .7rem;
    margin: 0;
  }

  .project .project-details .project-tags {
    width: 100%;
    padding: .7rem;
    margin: 0;
  }

  .project .demo-link {
    width: 100%;
    height: auto;
    display: flex;
    top: 0;
  }

  .project .demo-link .demo {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 3/2;
    max-width: none;
    margin: 0;
    padding: 0;
  }

  .project .funny-title:hover {
    transform: scale(1.05);
  }
}


.project h1 {
  font-size: 1rem;
  font-weight: 500;
}

/* for all hidden elements in the project component, add transition from hidden to show */
.project {
  opacity: 0;
  /*filter: blur(2px);*/
  transform: translateX(-5%);
  transition: all 1.5s;

  max-width: 100%;
  height: auto;
}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

/************ Footer Css ************/
.footer {
  padding: 1em;
  background-color: #121212;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer h1 {
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: clamp(1rem, 8vw, 1.5rem);
}
.footer p {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  padding: 0;
  margin: 0;
}
.socials-icons {
   display: flex;
   flex-direction: row;
   align-content: center;
   place-content: center;
   justify-content: center;
   width: 100%;
   height: auto;
   margin: 0;
  padding: 0;
 }
.socials-icons a {
  margin: 0 clamp(.5em, 1vw, 1em) 0 clamp(.5em, 1vw, 1em);
  min-width: 3em;
  height: auto;
  object-fit: contain;
  display: flex;
  justify-items: center;
  align-items: center;
}
.socials-icons img {
  max-height: 2.5em;
  object-fit: contain;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.socials-icons a:hover img {
  filter: drop-shadow(0em 0 0.3em rgba(231, 235, 229, 0.73));
  transform: scale(1.1);
}

/************ Contact Css ************/
.contact {
  background: linear-gradient(60deg, var(--pj3-color2a), var(--pj5-color2a));
  color: #E7EBE5FF;
  padding-bottom: 3rem;
  display: flex;
  align-items: center;
}
.contact .header-bg{
  background: url("./assets/img/hBG.png");
  background-size: 2.5rem;
}
.contact .contents {
  /*display both elements in the same line*/
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 1500px;
}
.contact .at-decor{
  width: 50%;
  height: auto;
  object-fit: contain;
  position: relative;
  place-self: start;
  top: 0;
  left: -3rem;
  transform: scale(1.2);
  margin-bottom: 3rem;
  max-height: 600px;
}

.contact form{
  width: 50%;
  height: auto;
  object-fit: contain;
  margin:2rem 4rem 0 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact h1{
  color: #E7EBE5FF;
}

.contact .contents form .input-group{
  /*display both elements in the same line*/
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin: 0 0 .8rem 0;
}
.contact .contents form .input-group div:nth-child(1){
  width: 50%;
  padding: 0 .4rem 0 0;
}
.contact .contents form .input-group div:nth-child(2){
  width: 50%;
  padding: 0 0 0 .4rem;
}

.contact form .input-group input{
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border: .1rem solid rgba(255, 255, 255, 0.25);
  border-radius: 1.5rem !important;
  color: #E7EBE5FF;
  padding: 1.3rem 1.5rem;
  font-weight: normal;
  font-size: large;
  transition: color, background-color 0.3s ease-in-out;
  min-height: 4rem;
}

.contact form textarea{
  height: 15rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border: .1rem solid rgba(255, 255, 255, 0.25);
  border-radius: 1.5rem;
  color: #E7EBE5FF;
  margin: 0;
  padding: 1.3rem 1.5rem;
  font-weight: normal;
  font-size: large;

  transition: color, background-color 0.3s ease-in-out;
  min-height: 10rem;
}

.contact form input:focus, .contact form textarea:focus {
  background: #E7EBE5FF;
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-weight: 400;
  color: #E7EBE5FF;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form .submit-button {
  /* more styles next to resume button */
  margin: 2rem .8rem 0 .8rem;
  width: 60%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  animation: funny-effect linear 120s infinite;
  transition: all 0.3s;
}

.contact form .submit-button span {
  /* more styles next to resume button */
  pointer-events: none;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact form .submit-button.success, .contact form .submit-button.success:hover{
  margin: 2rem .8rem 0 .8rem;
  background: #5dbb49;
  color: #E7EBE5FF;
  pointer-events: none;
}

.contact form .submit-button span.success, .contact form .submit-button.success:hover span{
  color: #E7EBE5FF;
  background: #5dbb49;
  border-color: #E7EBE5FF;
  background-size: 2.5rem;
  pointer-events: none;
}

.contact form .submit-button.error, .contact form .submit-button.error:hover{
  margin: 2rem .8rem 0 .8rem;
  color: #E7EBE5FF;
  background: #bb4949;
  pointer-events: none;
}

.contact form .submit-button span.error, .contact form .submit-button.error:hover span{
  color: #E7EBE5FF;
  background: #bb4949;
  border-color: #E7EBE5FF;
  background-size: 2.5rem;
  pointer-events: none;
}


@media screen and (max-width: 1200px) {
  .contact .at-decor{
    display: none;
  }

  .contact form{
    width: 100%;
    padding: 0;
    margin: 1rem;
  }
  .contact .contents form .input-group {
    /*display both elements in the same line*/
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact .contents form .input-group div:nth-child(1){
    width: 100%;
    padding: 0 0 .8rem 0;
  }
  .contact .contents form .input-group div:nth-child(2){
    width: 100%;
    padding: 0 0 0 0;
  }

}